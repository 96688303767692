<template>
    <div class="layindex">
        <div class="search-wrap">
            <van-search v-model="keyword" shape="round" background="none" placeholder="搜索商品" @clear="handleClear"
                @search="handleSearch" />
        </div>
        <div class="list">
            <template v-if="datalist.length > 0">
                <cube-scroll ref="scroll" :data="datalist" :options="options" @pulling-down="onPullingDown"
                    @pulling-up="onPullingUp">
                    <div class="item" v-for="(item, index) in datalist" :key="index" @click="jumpTo(item)">
                        <div class="main-img">
                            <van-image width="100%" height="100%" :src="item.img" fit="contain" />
                        </div>
                        <div class="info">
                            <div class="title">{{ item.name }}</div>
                            <div class="info">
                                <div class="price-wrap">
                                    <div class="price-box">
                                        <div class="price">{{ item.integral }}</div>
                                        <div class="unit">积分</div>
                                    </div>
                                    <div class="num">{{ item.sales_num }}人兑换</div>
                                </div>
                                <div class="btn">兑换</div>
                            </div>
                        </div>
                    </div>
                </cube-scroll>
            </template>
            <template v-else>
                <van-empty description="暂无数据" />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntegralShop',
    data () {
        return {
            keyword: "",
            datalist: [],
            page: 1,
            pullDownRefresh: false,
            pullDownRefreshThreshold: 50,
            pullDownRefreshStop: 40,
            pullDownRefreshTxt: '刷新成功',
            pullUpLoad: true,
            pullUpLoadThreshold: 10,
            pullUpLoadMoreTxt: '上滑加载更多',
            pullUpLoadNoMoreTxt: '我的有底线的',
        }
    },
    computed: {
        options () {
            return {
                pullDownRefresh: this.pullDownRefreshObj,
                pullUpLoad: this.pullUpLoadObj,
                scrollbar: false
            }
        },
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                txt: this.pullDownRefreshTxt,
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: {
                    more: this.pullUpLoadMoreTxt,
                    noMore: this.pullUpLoadNoMoreTxt
                },
                visible: true,
            } : false
        },
    },
    mounted () {
        this.getRechargelist(1, this.keyword);
        if (this.$store.state.userInfo.id > 0) {
            this.$utils.getWxJsConfig()
        }
    },
    methods: {
        handleSearch (value) {
            this.keyword = value
            this.getRechargelist(1, this.keyword);
        },
        handleClear (event) {
            this.keyword = ""
            this.getRechargelist(1, this.keyword);
        },
        onPullingDown () {
            this.page = 1;
            this.getRechargelist(1, this.keyword)
        },
        onPullingUp () {
            this.page = this.page + 1;
            this.getRechargelist(this.page, this.keyword)
        },
        getRechargelist (page, keyword) {
            this.$http.getIntegralGoodsList({
                'p': page,
                'keyword': keyword
            }).then(res => {
                if (res.code == 200) {
                    if (page == 1) {
                        this.datalist = [];
                    }
                    if (res.data.list.length > 0) {
                        this.datalist = this.datalist.concat(...res.data.list)
                    } else {
                        this.$refs.scroll.forceUpdate()
                    }

                    this.pullUpLoad = res.data.pages.p >= res.data.pages.total_page ? false : true;

                }
            }).catch(err => {
                console.log(err);
            });
        },
        jumpTo (good) {
            this.$router.push({ path: '/integral-goods', query: { id: good.id } });
        }
    },
}
</script>

<style scoped lang="less">
.layindex {
    width: 100%;
    height: 100%;
    position: relative;
    background: url(../assets/indexbg.png) no-repeat center;
    background-size: cover;
    overflow: hidden;
    z-index: 9;

    .search-wrap {
        padding: 34px 40px 0;

        ::v-deep {
            .van-search {
                padding: 0;
            }
        }
    }

    .list {
        margin-top: 18px;
        padding: 0 18px;

        .item {
            background: #FFFFFF;
            border-radius: 7px;
            width: calc(50% - 26px);
            height: calc(100% - 20px);
            padding: 8px;
            margin-bottom: 12px;

            .main-img {
                margin: 0 auto;
                width: 140px;
                height: 140px;
            }

            .info {
                margin: 6px 0;

                .title {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 18px;
                    font-size: 16px;
                    font-family: Source Han Sans CN, Source Han Sans CN;
                    font-weight: 400;
                    color: #707070;
                }

                .info {
                    margin-top: 11px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .price-wrap {
                        display: flex;
                        flex-direction: column;

                        .price-box {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;

                            .price {
                                height: 15px;
                                font-size: 18px;
                                font-family: Source Han Sans CN, Source Han Sans CN;
                                font-weight: bold;
                                color: #E31F1F;
                                line-height: 15px;
                            }

                            .unit {
                                margin: 0 3px;
                                display: block;
                                padding: 3px 0 2px;
                                font-size: 12px;
                                line-height: 8px;
                                height: 8px;
                                color: #E31F1F;
                            }
                        }


                        .num {
                            height: 8px;
                            line-height: 8px;
                            font-size: 9px;
                            font-family: Source Han Sans CN, Source Han Sans CN;
                            font-weight: 400;
                            color: #B3AFAF;
                            margin-top: 8px;
                            margin-left: 6px;
                        }
                    }

                    .btn {
                        background: #FAB021;
                        border-radius: 8px 8px 8px 8px;
                        padding: 3px 6px 4px;
                        height: 12px;
                        font-size: 12px;
                        font-family: Source Han Sans CN, Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                    }
                }
            }

        }

        ::v-deep {
            .cube-scroll-list-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                min-height: auto !important;
            }

            .cube-pullup-wrapper {
                color: #FFFFFF;
            }

            .van-empty__description {
                color: #FFFFFF;
            }
        }
    }

}
</style>
